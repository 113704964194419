import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Wrapper from './src/components/Wrapper'
import './global.css'

// wrapRootElement is part of the Gatsby API, and thus cannot be the default export
/* eslint-disable import/prefer-default-export, react/jsx-filename-extension */
export const wrapRootElement = ({ element }) => (
  <Wrapper>
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="hFgn8qNeubmaIXpXszIuJd5z-np2VqV9bL2hMqZmpm4"
        />
      </Helmet>
      {element}
    </>
  </Wrapper>
)

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}
